<template>
  <div class="information">
    <Banner isBig />
    <div class="information-content page-layout-big">
      <LoadingCard v-if="isLoading" />
      <md-card class="meep-form">
        <PageHeader
          :title="$t('users.view.title')"
          :has-actions="true"
          :has-back="true"
          @back="goBack"
        >
          <md-button
            type="button"
            class="md-primary"
            to="/dashboard/change-password"
          >
            {{ $t("information.change-password") }}
          </md-button>
        </PageHeader>
        <md-card-content>
          <div class="meep-form-column">
            <!-- Scope -->
            <div class="meep-input">
              <md-field>
                <label>
                  {{ $$filters.formatScope(self.scope) }}
                </label>
                <md-select v-model="self.scope" name="company" disabled />
              </md-field>
            </div>

            <!-- Nom -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('lastname') }">
                <label>{{ $t("form.lastName") }}*</label>
                <md-input
                  v-model="$v.user.lastname.$model"
                  name="lastname"
                  type="lastname"
                >
                </md-input>
                <template v-if="$v.user.lastname.$dirty">
                  <span
                    v-show="!$v.user.lastname.alphaSpace"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                  <span v-show="!$v.user.lastname.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Prénom -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('firstname') }">
                <label>{{ $t("form.firstName") }}*</label>
                <md-input
                  v-model="$v.user.firstname.$model"
                  name="firstname"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.firstname.$dirty">
                  <span
                    v-show="!$v.user.firstname.alphaSpace"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                  <span v-show="!$v.user.firstname.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Email -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('email') }">
                <label>E-mail*</label>
                <md-input v-model="$v.user.email.$model" name="email" />
                <template v-if="$v.user.email.$dirty">
                  <span v-show="!$v.user.email.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span v-show="!$v.user.email.email" class="meep-error">
                    {{ $t("meep-errors.email") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Téléphone fixe -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('telfixe') }">
                <label>{{ $t("form.telFix") }}</label>
                <md-input
                  v-model="$v.user.telfixe.$model"
                  name="telfixe"
                  type="tel"
                >
                </md-input>
                <template v-if="$v.user.telfixe.$dirty">
                  <span v-show="!$v.user.telfixe.tel" class="meep-error">
                    {{ $t("meep-errors.tel") }}
                  </span>
                </template>
              </md-field>
            </div>
          </div>
          <div class="meep-form-column">
            <!-- Téléphone portable  -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('telportable') }">
                <label>{{ $t("form.telMobile") }}</label>
                <md-input
                  v-model="$v.user.telportable.$model"
                  name="telportable"
                  type="tel"
                >
                </md-input>
                <template v-if="$v.user.telportable.$dirty">
                  <span v-show="!$v.user.telportable.tel" class="meep-error">
                    {{ $t("meep-errors.tel") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Numéro et voie -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.address") }}</label>
                <md-input
                  v-model="$v.user.adresse.$model"
                  name="adresse"
                  type="text"
                >
                </md-input>
              </md-field>
            </div>

            <!-- Ville -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('ville') }">
                <label>{{ $t("form.city") }}</label>
                <md-input
                  v-model="$v.user.ville.$model"
                  name="ville"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.ville.$dirty">
                  <span v-show="!$v.user.ville.alphaSpace" class="meep-error">
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Code postal -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
                <label>{{ $t("form.postalCode") }}</label>
                <md-input
                  v-model="$v.user.codepostal.$model"
                  name="codepostal"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.codepostal.$dirty">
                  <span v-show="!$v.user.codepostal.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <div v-if="!$v.user.codepostal.maxLength" class="meep-error">
                    {{
                      $t("meep-errors.max", {
                        max: $v.user.codepostal.$params.maxLength.max,
                      })
                    }}
                  </div>
                </template>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right">
              <md-button class="md-raised md-primary" @click="editUser">
                {{ $t("users.view.confỉm-button") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <confirm-action-modal
      v-if="isConfirmModalOpen"
      :text="confirmModalText"
      :object-to-act-upon="{}"
      @close="isConfirmModalOpen = false"
      @confirm="onConfirmModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, numeric, email, maxLength } from "vuelidate/lib/validators";

import Banner from "@/components/Banner";
import PageHeader from "@/components/PageHeader";
import ConfirmActionModal from "../components/modal/confirm-action.vue";

import usersModel from "../model/users";

import { compareObjects } from "@/services/util";
import { alphaSpace, tel } from "../services/vuelidate";

import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";
import HasBackMixin from "@/mixins/HasBackMixin";

export default {
  name: "Information",

  components: {
    Banner,
    PageHeader,
    ConfirmActionModal,
  },

  mixins: [HasBackMixin, ForceLeaveMixin],

  validations: {
    user: {
      firstname: {
        alphaSpace,
        required,
      },
      lastname: {
        alphaSpace,
        required,
      },

      email: { email, required },
      telfixe: { tel },
      telportable: { tel },
      adresse: {},
      ville: { alphaSpace },
      codepostal: { numeric, maxLength: maxLength(5) },
    },
  },

  data() {
    return {
      isLoading: false,
      user: {
        firstname: "",
        lastname: "",
        email: "",
        telfixe: "",
        telportable: "",
        adresse: "",
        ville: "",
        country: "",
        codepostal: null,
      },
      isConfirmModalOpen: false,
    };
  },

  computed: {
    ...mapGetters(["self"]),

    confirmModalText() {
      switch (this.confirmationContext) {
        default:
          return {
            header: this.$t("info.modal.title"),
            body: () => {
              return this.$t("info.modal.body");
            },

            question: this.$t("info.modal.question"),
          };
      }
    },

    isAnyModified() {
      return !compareObjects(this.user, this.self);
    },
  },

  mounted() {
    this.user = { ...this.self };
  },

  methods: {
    ...mapActions(["UPDATE_SELF"]),
    async editUser() {
      const result = !this.$v.$invalid;
      if (result) {
        try {
          await usersModel.update(this.user);

          this.UPDATE_SELF(this.user);

          this.$toasted.global.AppSucces({
            message: this.$t("information.success-message"),
          });
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },

    onConfirmModal() {
      switch (this.confirmationContext) {
        default:
          this.forceRouteLeave();
      }
    },

    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style lang="scss">
.information {
  .md-card {
    margin: 0;
  }

  &-content {
    .page-header__actions {
      .md-primary {
        padding: 0 20px;
      }
    }
    .md-primary {
      padding: 0 24px;
    }
  }
}
</style>
